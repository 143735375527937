.services_out {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh);
  background-image: url("../Images/services.jpg"); 
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* position: relative;
  top: -20px; */
}
/* @media (max-width: 760px) {
  .services_out {
    top: 0px;
  }
} */
h3 {
  text-align: center;
}

.services_in {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services_content {
  padding: 20px;
  width: 300px;
  min-height: 360px;
  background-color: rgb(48, 47, 47, 0.9);
  margin: 20px 20px;
  border-radius: 5px;
}

@media (max-width: 760px) {
  .services_in {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .services_content {
    width: calc(100vw - 40px);
  }
}
.topic {
  width: calc(100% - 20px);
  margin: 10px;
  height: 40px;
  background-color: white;
  border-radius: 2px;
  padding-top: 5px;
}
.contents {
  margin: 10px;
  color: white;
  text-align: justify;
  text-justify: inter-word;
}
