.contact_out {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh);
  background-image: url("../Images/visitingcard.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  
  
  /* position: relative;
  top: -20px; */
}
/* @media (max-width: 760px) {
  .contact_out {
    top: 0px;
  }
} */
.contact_in {
  display: flex;
  justify-content: space-between;
  align-items: center;margin-top: 60px;
  
}
.description{
 
  width:400px;
  height: 550px;
  margin: 10px;
  background-color: rgb(36, 38, 39, 0.9);
  border-radius: 3px;
}
.topic1{
  color: aliceblue;
}
.topic2{
  color: red;
  font-size: 16px;
  font-weight: bold;
}
.details{
  color: aliceblue;
  margin: 5px;
}

@media (max-width: 760px) {
  .contact_in {
    display: block;
  }
  .description{
    width:calc(100vw - 20px);
    height: 100vh;
    margin-top: 70px;
    color: aliceblue;
  }
}
.fa-phones{
  color: rgb(253, 219, 27);
  font-size: 28px;
  margin: 10px;
}
.fa-phones1{
  padding: 5px;
  color: green;
  background-color: rgb(255, 255, 255);
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.description1{
  display:flex;
  align-items:center;
  background-color: rgb(0, 0, 0, 0);
}


