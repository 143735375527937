.nfout{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.nfin{
    display: block;
}
h1,h3{
    text-align: center;
    color:red;
}